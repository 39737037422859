/* ============ spartan fonts ============ */
// @font-face {
// 	font-family: "Spartan";
// 	src: url("../fonts/spartan/Spartan-ExtraBold.woff2") format("woff2"), url("../fonts/spartan/Spartan-ExtraBold.woff") format("woff"), url("../fonts/spartan/Spartan-ExtraBold.svg#Spartan-ExtraBold") format("svg");
// 	font-weight: bold;
// 	font-style: normal;
// 	font-display: swap;
// }
// @font-face {
// 	font-family: "Spartan";
// 	src: url("../fonts/spartan/Spartan-Black.woff2") format("woff2"), url("../fonts/spartan/Spartan-Black.woff") format("woff"), url("../fonts/spartan/Spartan-Black.svg#Spartan-Black") format("svg");
// 	font-weight: 900;
// 	font-style: normal;
// 	font-display: swap;
// }
// @font-face {
// 	font-family: "Spartan";
// 	src: url("../fonts/spartan/Spartan-Bold.woff2") format("woff2"), url("../fonts/spartan/Spartan-Bold.woff") format("woff"), url("../fonts/spartan/Spartan-Bold.svg#Spartan-Bold") format("svg");
// 	font-weight: bold;
// 	font-style: normal;
// 	font-display: swap;
// }
// @font-face {
// 	font-family: "Spartan";
// 	src: url("../fonts/spartan/Spartan-Medium.woff2") format("woff2"), url("../fonts/spartan/Spartan-Medium.woff") format("woff"), url("../fonts/spartan/Spartan-Medium.svg#Spartan-Medium") format("svg");
// 	font-weight: 500;
// 	font-style: normal;
// 	font-display: swap;
// }
// @font-face {
// 	font-family: "Spartan";
// 	src: url("../fonts/spartan/Spartan-Regular.woff2") format("woff2"), url("../fonts/spartan/Spartan-Regular.woff") format("woff"), url("../fonts/spartan/Spartan-Regular.svg#Spartan-Regular") format("svg");
// 	font-weight: normal;
// 	font-style: normal;
// 	font-display: swap;
// }
// @font-face {
// 	font-family: "Spartan";
// 	src: url("../fonts/spartan/Spartan-SemiBold.woff2") format("woff2"), url("../fonts/spartan/Spartan-SemiBold.woff") format("woff"), url("../fonts/spartan/Spartan-SemiBold.svg#Spartan-SemiBold") format("svg");
// 	font-weight: 600;
// 	font-style: normal;
// 	font-display: swap;
// }
// /* ============ poppins fonts ============ */
// @font-face {
// 	font-family: "Poppins";
// 	src: url("../fonts/poppins/Poppins-Black.woff2") format("woff2"), url("../fonts/poppins/Poppins-Black.woff") format("woff"), url("../fonts/poppins/Poppins-Black.svg#Poppins-Black") format("svg");
// 	font-weight: 900;
// 	font-style: normal;
// 	font-display: swap;
// }
// @font-face {
// 	font-family: "Poppins";
// 	src: url("../fonts/poppins/Poppins-Bold.woff2") format("woff2"), url("../fonts/poppins/Poppins-Bold.woff") format("woff"), url("../fonts/poppins/Poppins-Bold.svg#Poppins-Bold") format("svg");
// 	font-weight: bold;
// 	font-style: normal;
// 	font-display: swap;
// }
// @font-face {
// 	font-family: "Poppins";
// 	src: url("../fonts/poppins/Poppins-ExtraBold.woff2") format("woff2"), url("../fonts/poppins/Poppins-ExtraBold.woff") format("woff"), url("../fonts/poppins/Poppins-ExtraBold.svg#Poppins-ExtraBold") format("svg");
// 	font-weight: bold;
// 	font-style: normal;
// 	font-display: swap;
// }
// @font-face {
// 	font-family: "Poppins";
// 	src: url("../fonts/poppins/Poppins-Medium.woff2") format("woff2"), url("../fonts/poppins/Poppins-Medium.woff") format("woff"), url("../fonts/poppins/Poppins-Medium.svg#Poppins-Medium") format("svg");
// 	font-weight: 500;
// 	font-style: normal;
// 	font-display: swap;
// }
// @font-face {
// 	font-family: "Poppins";
// 	src: url("../fonts/poppins/Poppins-Regular.woff2") format("woff2"), url("../fonts/poppins/Poppins-Regular.woff") format("woff"), url("../fonts/poppins/Poppins-Regular.svg#Poppins-Regular") format("svg");
// 	font-weight: normal;
// 	font-style: normal;
// 	font-display: swap;
// }
// @font-face {
// 	font-family: "Poppins";
// 	src: url("../fonts/poppins/Poppins-SemiBold.woff2") format("woff2"), url("../fonts/poppins/Poppins-SemiBold.woff") format("woff"), url("../fonts/poppins/Poppins-SemiBold.svg#Poppins-SemiBold") format("svg");
// 	font-weight: 600;
// 	font-style: normal;
// 	font-display: swap;
// }
body {
	font-family: "Poppins" !important;
	background-color: #faf6ec !important;
}
body h1, body h2, body h3, body h4, body h5, body h6, body p, body a, .MuiButton-root {
	font-family: 'Poppins' !important;
}
.sliderSection .textContent p::-webkit-scrollbar,
.sc-dmctIk::-webkit-scrollbar {
	height: 9px;
	border: 1px solid #d5d5d5;
	border-radius: 5px;
}
.sliderSection .textContent p::-webkit-scrollbar-track,
.sc-dmctIk::-webkit-scrollbar-track {
	border-radius: 5px;
	background: #eee;
	border: 0;
}
.sliderSection .textContent p::-webkit-scrollbar-thumb,
.sc-dmctIk::-webkit-scrollbar-thumb {
	border-radius: 5px;
	background: #9e9e9e;
}
body .gKbhqU {
	border-top-width: 0;
}
.react-player > video[controls]::before {
	content: attr(data-disable-controls, normal);
	display: none;
}
/* ============= login form css ============= */


.faq-popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
  }
  
  .faq-popup-main {
	background: white;
	padding: 20px;
	border-radius: 5px;
	max-width: 800px;
    width: 90%;
	max-height: 80vh;
	overflow-y: auto;
	// text-align: center;
	position: relative;
	z-index: 99999999;
  }
  
  .faq-popup-close {
	margin-top: 20px;
	padding: 5px 10px;
	background-color: #007bff;
	border: none;
	color: white;
	border-radius: 3px;
	cursor: pointer;
  }
  
  .display-block {
	display: flex;
  }
  
  .display-none {
	display: none;
  }
  



.closeBtn {
	color: #000;
	font-size: 35px;
	transform: rotate(135deg);
	background-color: transparent;
	border: 0;
	position: absolute;
	top: -3px;
	right: 3px;
	cursor: pointer;
}
.assignment_detail_teacher .edited_assin .view_link {
	position: relative;
	left: 30px;
}
.text_color_white{
	color: #fff;
    line-height: 2em;
}
.text_color_white a{
	color: #aa9865;
}
.ck.ck-balloon-panel.ck-balloon-panel_visible {
    z-index: 999999;
}
.faqs_form .ck.ck-editor__main > .ck-editor__editable {
    min-height: 250px !important;
    max-height: 250px !important;
}
.ck.ck-editor__main > .ck-editor__editable {
	min-height: 500px !important;
	max-height: 500px !important;
}
.subject_edit_content .ck.ck-editor__main > .ck-editor__editable {
    min-height: 220px !important;
    max-height: 300px !important;
    overflow-y: auto;
}


.ck.ck-balloon-panel.ck-powered-by-balloon[class*=position_border] {
	opacity: 0;
}
body .react-tel-input .form-control {
	height: 44px;
	width: 100%;
	border: 2px solid #e1e2e5;
}
body .react-date-picker__inputGroup__input {
	padding: 0 1px !important;
	border: 0 !important;
	outline: 0;
}
body .react-date-picker__wrapper {
	padding: 10px 10px;
	background-color: #fff;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	color: #071836;
	border: 2px solid #e1e2e5;
	border-radius: 5px;
}
body .react-date-picker {
	width: 100%;
}
.react-datepicker-wrapper {
	width: 100%;
}
.react-datepicker-wrapper .react-datepicker__input-container input {
	width: 100%;
}
.register-wrap {
	margin: 0 auto;
}
.parent_regiter .btn {
	max-width: 181px;
	margin-left: auto;
	display: block;
	width: 100%;
	padding: 10px 15px;
}
.parent_regiter .MuiOutlinedInput-input {
	border-radius: 4px;
	position: relative;
	background-color: #fcfcfb;
	border: 1px solid #ced4da;
	font-size: 16px;
	width: 100%;
	padding: 10px 12px;
}
.student_data_picker {
	padding: 9px 18px;
	font-size: 14px;
	font-family: "Poppins";
	background: #fff;
	border: 2px solid rgba(7, 24, 54, 0.1);
	border-radius: 5px;
}
.identity_picture label {
	display: flex;
	background: none;
	margin-top: 17px;
	width: 100%;
	max-width: 200px;
	flex-direction: column;
}
.identity_picture .css-sghohy-MuiButtonBase-root-MuiButton-root:hover {
	background-color: transparent;
}
.identity_picture div > img {
	width: 100%;
	max-width: 250px;
	height: 154px;
	margin-top: 13px;
}
.login-wrap {
	background-color: #fff;
	width: 95%;
	max-width: 612px;
	padding: 40px 70px;
	box-shadow: 0px 24px 44px #000;
	border-radius: 5px;
}
.login-wrap .login-form .darkbeige {
	text-transform: capitalize;
	background: linear-gradient(91.35deg, #a3915e 1.15%, #d6c38f 66.97%);
	box-shadow: none;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 21px;
	padding: 13px 12px 11px;
	color: #fff!important;
}
.login-wrap .login-form label {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 21px;
	color: #071836;
}
.login-wrap .login-form input:focus {
	border: 2px solid #071836;
	box-shadow: none;
}
.login-wrap h1 {
	font-family: "Poppins";
	font-weight: 700;
	font-size: 1.5em;
	line-height: 2;
	text-align: center;
	color: #071836;
}
.login-wrap.create-password .login-form input:focus {
	border: 0;
	box-shadow: none;
}
body .login-form-bg {
	padding: 20px 0;
    min-height: 1073px;
		display: flex;
	flex-direction: column;
	justify-content: center;
	background-image: url("../images/books-desk-library.png");
	background-size: cover;
	background-repeat: no-repeat;
}
.logo-wrap {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.forget-link a {
	font-family: "Poppins";
	font-weight: 500;
	font-size: 16px;
	color: #9a909c;
	line-height: 24px;
	text-align: center;
	width: 100%;
	display: block;
	text-decoration: none;
}
.togglebutton {
	position: absolute;
	left: 10px;
	width: 45px;
	height: 42px;
	top: 24px;
	z-index: 9999;
	cursor: pointer;
}
/* ============= layout ============= */
.side-bar > .MuiPaper-root {
	background: linear-gradient(180deg, #071836 0%, #273a5c 35.58%);
}
body .main-logo {
	background: none;
	min-height: 90px;
}
body .top_header {
	box-shadow: none;
	padding: 13px 40px 13px 78px;
	background-color: #fffefd;
}
body .main-header {
	background-color: #fffefd;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
}
.main_spacer {
	background: transparent !important;
	min-height: 40px;
}
/* ============= footer css ============= */
/* ============= Dashboard Menu Css ============= */
.main_spacer.css-e9bryc {
	background: transparent;
	min-height: 40px;
}
body .top_header {
	box-shadow: none;
}
body .open_icon {
	background-color: #fff;
	margin-left: 11px;
	width: 30px;
	height: 30px;
}
body .open_icon:hover {
	background-color: #fff;
}
.sidebar-menu li a {
	color: #fffefd;
}
.sidebar-menu .menu-wrap {
	margin-top: 65px;
}
.sidebar-menu .menu-wrap li {
	list-style-type: none;
}
.sidebar-menu .menu-wrap li a {
	text-decoration: none;
	display: inline-block;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 18px;
}
.sidebar-menu .menu-wrap li .sub-menu {
	margin-left: 0px;
}
.sidebar-menu .menu-wrap li .sub-menu li a {
	padding: 17px 0px;
	color: #071836;
}
.sidebar-menu .menu-wrap > li {
	background-repeat: no-repeat;
	background-position: 21px center;
	background-color: rgba(255, 255, 255, 0.02);
	margin: 11px 0px 0px 0px;
	border-radius: 5px 0px 0px 5px;
	transition: 0.6s all;
}
.sidebar-menu .menu-wrap > li:hover {
	background-color: #b5a16a;
}
.sidebar-menu .menu-wrap > li a {
	padding: 17px 0px 17px 61px;
	width: 100%;
}
.sidebar-menu .menu-wrap > li .users-icon {
	background-image: url("../images/Users.png");
	background-position: 21px center;
	background-repeat: no-repeat;
}
.sidebar-menu .menu-wrap > li .finances-icon {
	background-image: url("../images/finances-icon.svg");
	background-position: 21px center;
	background-repeat: no-repeat;
}




.sidebar-menu .menu-wrap > li.users-icon-stu {
	background-image: url("../images/Users.png");
	background-position: 21px center;
	background-repeat: no-repeat;
}
.sidebar-menu .menu-wrap > li .cms-icon {
	background-image: url("../images/cms-icon.png");
	background-position: 21px center;
	background-repeat: no-repeat;
}
.sidebar-menu .menu-wrap > li.complaints-icon{
	background-image: url("../images/complaints-icon.png");
	background-position: 21px center;
	background-repeat: no-repeat;
}
.sidebar-menu .menu-wrap > li.Admin-icon{
	background-image: url("../images/Admin-icon.png");
	background-position: 21px center;
	background-repeat: no-repeat;
}
.sidebar-menu .menu-wrap > li.access-icon{
	background-image: url("../images/access-icon.png");
	background-position: 21px center;
	background-repeat: no-repeat;
}
.sidebar-menu .menu-wrap > li.noticeboard-icon{
	background-image: url("../images/noticeboard-icon.png");
	background-position: 21px center;
	background-repeat: no-repeat;
}
.sidebar-menu .menu-wrap > li.questionsbank-icon{
	background-image: url("../images/questionsbank-icon.png");
	background-position: 21px center;
	background-repeat: no-repeat;
}
.sidebar-menu .menu-wrap > li.quiz-menu-icon{
	background-image: url("../images/quiz-menu-icon.png");
	background-position: 21px center;
	background-repeat: no-repeat;
}



.sidebar-menu .menu-wrap > li.active {
	background-color: #b5a16a;
}
.sidebar-menu .menu-wrap > li.active .finances-icon,
.sidebar-menu .menu-wrap > li.active .finances-icon {
	filter: grayscale(100%) brightness(130%);
}
.sidebar-menu .menu-wrap li.has-child .sub-menu {
	border-top: 1px solid #071836;
	padding-left: 66px;
	background-color: #fefcf7;

}


.sidebar-menu .menu-wrap li.has-child.finances-icon .sub-menu {
    padding-left: 35px;
}

.sidebar-menu .menu-wrap li.has-child span a {
	display: inline-block;
	padding: 17px 0px 17px 61px;
}
.sidebar-menu .menu-wrap > li.dashboard-icon {
	background-image: url("../images/Dashboard.png");
}
.sidebar-menu .menu-wrap > li.courses-icon {
	background-image: url("../images/Courses.png");
}
.sidebar-menu .menu-wrap > li.quizzes-icon {
	background-image: url("../images/Quizzes.png");
}
.sidebar-menu .menu-wrap > li.enrolled-students-icon {
	background-image: url("../images/enrolled-students-icon.png");
}
.sidebar-menu .menu-wrap > li.assigment-icon {
	background-image: url("../images/assigment-icon.png");
}
.sidebar-menu .menu-wrap > li.subject-catalog-icon {
	background-image: url("../images/subject-catalog-icon.png");
}
.sidebar-menu .menu-wrap > li.chat-icon {
	background-image: url("../images/Chat.png");
}
.sidebar-menu .menu-wrap > li.forums-icon {
	background-image: url("../images/Forums.png");
}
.sidebar-menu .menu-wrap > li.messaging-icon {
	background-image: url("../images/Messaging.png");
}
// .sidebar-menu .menu-wrap > li.finances-icon {
// 	background-image: url("../images/Finances.png");
// }
.sidebar-menu .menu-wrap > li.settings-icon {
	background-image: url("../images/Settings.png");
}
.sidebar-menu .menu-wrap > li.profile-icon {
	background-image: url("../images/UserProfile.png");
}
.sidebar-menu .menu-wrap > li.progress-icon {
	background-image: url("../images/Success.png");
}
.sidebar-menu .menu-wrap > li.transactions-icon {
	background-image: url("../images/transactions-icon.png");
}
.loginUser .userName {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	margin-bottom: 4px;
	font-size: 16px;
	text-align: center;
	line-height: 19px;
	color: #a3915e;
}
.loginUser .signOut {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	cursor: pointer;
	text-align: center;
	line-height: 17px;
	color: #071836;
}
/* ===== Scrollbar CSS ===== */
/* Firefox */
/* Chrome, Edge, and Safari */
.side-bar .MuiDrawer-paper {
	scrollbar-width: auto;
}
.side-bar .MuiDrawer-paper::-webkit-scrollbar {
	width: 10px;
}
.side-bar .MuiDrawer-paper::-webkit-scrollbar-track {
	background: #eee;
}
.side-bar .MuiDrawer-paper::-webkit-scrollbar-thumb {
	background: #9e9e9e;
}
/* ============= top_header_inner Css ============= */
.dashbaord-card {
	box-shadow: 0px 24px 44px rgba(37, 10, 43, 0.04);
}
.dashbaord-card h3 {
	margin-top: 11px;
	font-family: "Spartan";
	font-style: normal;
	font-weight: 800;
	font-size: 36px;
	line-height: 43px;
	color: #071836;
}
.dashbaord-card h3.progress  {
	font-size: 27px;
	text-transform: capitalize;
}
.dashbaord-card p {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	color: #071836;
	min-height: 39px;
}
.footer p {
	color: #fffefd;
}
.footer ul li a {
	color: #fffefd;
}
.dashboard-footer {
	position: absolute;
	width: 100%;
	left: 0;
	bottom: 0;
	right: 0;
	margin: auto;
	max-width: 1280px;
	padding: 0 10px;
}
.dashboard-footer .footer {
	display: flex;
	justify-content: space-between;
}
.dashboard-footer .footer ul li a {
	color: #071836;
}
.dashboard-footer .footer .footer_text p {
	color: #071836;
}
.top_header_inner a {
	text-decoration: none;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 58px;
	color: #071836;
}
.header-search {
	position: relative;
}
.header-search .MuiInputBase-root {
	display: flex;
	flex-direction: row-reverse;
	padding-right: 10px;
	background-color: #f8f4ea;
	border: none;
}
.header-search .MuiInputBase-root fieldset {
	border: none;
	font-family: "Poppins";
}
.header-search .MuiInputBase-root input {
	border: none;
	font-family: "Poppins";
}
body .MuiFormLabel-root {
	font-family: "Poppins";
}
.pagination_removed .rdt_Pagination{
display: none;
}
.header-search .MuiInputBase-root::placeholder {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 21px;
	color: #071836;
}
.header-search .search_result {
	background-color: #fff;
	color: #000;
	box-shadow: -1px -2px 12px 3px #000;
	-webkit-box-shadow: -1px -2px 12px 3px #000;
	-moz-box-shadow: -1px -2px 12px 3px rgba(0, 0, 0, 0.75);
	position: absolute;
	top: 49px;
	width: 100%;
	padding: 15px;
}
.header-search .search_result .search_course_heding {
	font-weight: 700;
	font-size: 18px;
	border-bottom: 1px solid #e1e1e1;
	padding-bottom: 8px;
}
.header-search .search_result .course_list {
	font-size: 14px;
	font-weight: normal;
}
.header-search .search_result .search_course_show {
	margin-bottom: 15px;
}
.header-search .search_result .search_course_show a {
	line-height: normal;
}
/* ============= DataTable Css ============= */
.tab {
	background-color: #f3eace;
	padding: 6px;
}
.tab button {
	text-transform: capitalize;
	color: #071836;
	border-bottom: 0;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	text-align: center;
	padding: 15px 48px;
	border-radius: 5px;
}
.tab button.Mui-selected {
	color: #fff;
	background-color: #071836;
}
.table-container .MuiTabs-indicator {
	display: none;
}
.page-container {
	margin-bottom: 25px;
	padding: 47px 33px;
	background: #fff;
	border-radius: 5px;
}
.page-container .sc-dmctIk {
	min-height: 190px;
}
.page-container .rdt_TableHead .rdt_TableHeadRow {
	background-color: #071836;
	min-height: 45.5px;
	border-radius: 5px;
}
.page-container .rdt_TableHead .rdt_TableHeadRow div {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-size: 13px;
	line-height: 16px;
	color: #fffefd;
}
.rdt_Table .parent_not_marked .rdt_TableCell{
	background-color: #ff980078;

}
.page-container .rdt_TableBody .rdt_TableCell div {
	padding: 21px 0;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #071836;
}

.subject_create_quiz .MuiTabs-scroller button:last-child{
pointer-events: none;
}
.subject_create_quiz .MuiTabs-scroller button.is_active{
pointer-events: unset;
}
.page-container .rdt_TableBody .rdt_TableCell .MuiAvatar-root {
padding: 0;
}

body .MuiFormHelperText-root.Mui-error {
    margin-left: 0;
}
body .main-content {
	background-color: #faf6ec;
	padding: 70px 75px;
	overflow: hidden;
}

body.student-report-pdf .main-content {
    padding: 40px 25px 40px;
}
body.student-report-pdf .btn.btn_single_subject {

    position: absolute;
    right: 2.5%;
    margin-top: 37px;
}

body.student-report-pdf .btn {
    margin-left: auto;
    display: block;
    position: relative;
    right: 20px;
	margin-top: 15px;
}


body button{
	font-family: "Poppins";
}
body .btn {
	border: none;
	cursor: pointer;
	box-shadow: none;
	display: inline-block;
	text-decoration: none;
	background: linear-gradient(91.35deg, #a3915e 1.15%, #d6c38f 66.97%);
	border-radius: 5px;
	padding: 10px 35px;
	color: #fff;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 21px;
	text-align: center;
	text-transform: capitalize;
}
body .modal .btn {
	box-shadow: none;
	margin-top: 20px;
}
body .modal .btn:hover {
	box-shadow: none;
}
body .edit {
	background-repeat: no-repeat;
	padding: 10px;
	font-size: 0px !important;
	height: 21px;
	min-width: 21px !important;
	margin-right: 6px;
}
body .view {
	background-repeat: no-repeat;
	padding: 10px;
	font-size: 0px !important;
	height: 21px;
	min-width: 21px !important;
	margin-right: 6px;
}
body .delete {
	background-repeat: no-repeat;
	padding: 10px;
	font-size: 0px !important;
	height: 21px;
	min-width: 21px !important;
	margin-right: 6px;
}
.btn-wrapper {
	text-align: right;
}
.btn-wrapper a {
	border: none;
	cursor: pointer;
	display: inline-block;
	text-decoration: none;
	background: linear-gradient(91.35deg, #a3915e 1.15%, #d6c38f 66.97%);
	border-radius: 5px;
	padding: 12px 42px;
	color: #fff;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 21px;
	text-align: center;
	text-transform: capitalize;
}
.edit {
	background-color: transparent !important;
	background-image: url("../images/edit.jpg");
}
.delete {
	background-image: url("../images/trash.jpg");
}
.delete:hover {
	background-color: transparent !important;
}
.view {
	background-image: url("../images/view.jpg");
}
.Search-By {
	display: flex;
}
.Search-By label {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 21px;
	color: #071836;
}
.Search-By input {
	max-width: 200px;
}
.Search-By input:focus {
	border: 2px solid #071836;
	box-shadow: none;
}
.course-content {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.course-content > div {
	width: 100%;
	margin-bottom: 3px;
	cursor: pointer;
}
.quiz_result_detail .question_wrap {
	background-color: #f3eace;
	display: flex;
	padding: 40px 38px;
}
.quiz_result_detail .question_wrap h2 {
	font-size: 24px;
	font-weight: 700;
	font-family: "Poppins";
	margin-right: 25px;
	color: #142646;
}
.quiz_result_detail fieldset {
	display: flex;
	position: relative;
}
.quiz_result_detail fieldset label {
	margin: 5px 0;
}
.quiz_result_detail fieldset .slected {
	position: absolute;
	right: 3px;
	top: 8px;
	padding: 6px 7px;
	background: #f3eace;
}
.quiz_result_detail .incorrect {
	background-color: #f00 3b;
}
.quiz_result_detail .corect {
	background-color: #8aff8a 82;
}
/* =========== edit role =========== */
.login-form label {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 21px;
	color: #071836;
}
.login-form input:focus {
	border: 2px solid #071836;
	box-shadow: none;
}
.table-head {
	background-color: #071836;
	border-radius: 5px;
}
.table-head .table-heading {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-size: 13px;
	line-height: 16px;
	color: #fffefd;
	text-transform: capitalize;
	padding: 14px;
}
.table-body .table-cell {
	text-transform: capitalize;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #071836;
}
.table-body span.Mui-checked {
	color: #d6c38f;
}
.table-body span:hover {
	background-color: transparent;
}
.page-container .btn {
	padding: 12px 50px;
	margin-left: auto;
	display: block;
	box-shadow: none;
}
.page-container .btn:hover {
	box-shadow: none;
}
.page-container .field label {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 21px;
	color: #071836;
}
.page-container .field input {
	font-family: "Poppins";

	margin-bottom: 20px;
}
.page-container .PrivateSwitchBase {
	font-family: "Poppins";
}
.page-container .field input:focus {
	border: 2px solid #071836;
	box-shadow: none;
}
h1.main-heading {
	font-style: normal;
	font-weight: 800;
	font-size: 40px;
	line-height: 62px;
	color: #071836;
}
/* =========== delete popup =========== */
#alert-dialog-title {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 58px;
	color: #071836;
}
#alert-dialog-description {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	text-align: center;
	color: #071836;
}
/* =========== eidt quiz =========== */
.inner-container {
	padding: 10px 25px;
}
.inner-container form label {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 21px;
	color: #071836;
}
.inner-container form select {
	padding: 10px 10px;
	background-color: #fff;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	color: #071836;
	border: 2px solid #e1e2e5;
	border-radius: 5px;
}
.upload_progress{
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    width: 100%;
    max-width: 229px;
    right: 20px;
    position: fixed;
    top: 22px;
    z-index: 9999; 
}

.inner-container form .MuiTextarea-root {
	padding: 10px 10px;
	background-color: #fff;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	color: #071836;
	border: 2px solid #e1e2e5;
	border-radius: 5px;
}
body .MuiInputBase-root{
	font-family: "Poppins";

}
.inner-container form .MuiSelect-select {
	padding: 10px 10px;
	background-color: #fff;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	color: #071836;
	border: 2px solid #e1e2e5;
	border-radius: 5px;
}

.inner-container form input {
	padding: 10px 10px;
	background-color: #fff;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	color: #071836;
	border: 2px solid #e1e2e5;
	border-radius: 5px;
}
.inner-container form .input_border_remove input{
	border: 0;
}
.inner-container form input::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
.inner-container form input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
.inner-container form input[type="number"] {
	-moz-appearance: textfield;
	appearance: textfield;
}
.inner-container form .joy-focused .MuiTextarea-textarea {
	border-color: #071836;
	box-shadow: inset 0 0 0 var(--Textarea-focusedThickness) #071836;
}
.inner-container form .Joy-focused:before {
	border-color: #071836;
	box-shadow: inset 0 0 0 var(--Textarea-focusedThickness) #071836;
}
.inner-container form .MuiTextarea-root.Joy-focused:before {
	border-color: #071836;
	box-shadow: inset 0 0 0 var(--Textarea-focusedThickness) #071836;
}
.inner-container form .Mui-focused .MuiOutlinedInput-notchedOutline {
	border-color: #071836;
	box-shadow: inset 0 0 0 var(--Textarea-focusedThickness) #071836;
}
.quiz-heading-row {
	background-color: #f8f7f7;
}
.quiz-heading-row th {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-size: 13px;
	line-height: 16px;
	color: #071836;
}
/* =========== add / edit quiz =========== */
body .light-btn {
	padding: 13px 26px;
	padding: 13px 26px;
	box-shadow: none;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 21px;
	background-color: #faf6ec;
	color: #071836;
}
body .dark-btn {
	padding: 13px 26px;
	padding: 13px 26px;
	box-shadow: none;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 21px;
	background-color: #071836;
	color: #fff;
	padding: 8px 16px;
	font-size: 12px;
	text-transform: capitalize;
}
body .dark-btn:hover {
	box-shadow: none;
	background-color: #071836;
}
body .page-container .light-btn {
	padding: 13px 26px;
	padding: 13px 26px;
	box-shadow: none;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 21px;
	background: #faf6ec;
	color: #071836;
}
.page-container .light-btn:hover {
	box-shadow: none;
	background-color: #faf6ec;
}
.page-container .upload-image.profile {
	background-color: #faf6ec;
	border: 3px dashed rgba(7, 24, 54, 0.1);
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
.page-container .upload-image.profile label {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 30px;
	text-align: center;
	color: #071836;
	background-color: transparent;
	box-shadow: none;
	display: flex;
	flex-direction: column;
	z-index: 1;
	transition: all 0.5s;
}
.page-container .upload-image.profile:hover label {
	z-index: 99;
}
.page-container .upload-image.profile .Preview_image {
	position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: 9;
    border-radius: 100%;
	right: 0;
}
// .course-content .course-section {
// 	background-color: #000;
// }
.course-content .course-section h3 {
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 120%;
	color: #071836;
	padding-left: 10px;
	display: flex;
	padding-right: 20px;
}
.course-content .course-section h3 .sec_title {
    max-width: 130px;
    width: 100%;
}
.course-content .course-section h3 span {
	font-weight: 400;
	padding-right: 40px;
}
.course-content .course-section .light-btn {
	padding: 10px 15px;
	border: 1px solid #a99764;
}
// .quiz-section {
// 	border-bottom: 1px solid #edeced;
// }
.quiz-section h2 {
	color: rgba(7, 24, 54, 1);
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 1;
	padding: 15px 15px;
	display: flex;
	align-items: center;
}
.quiz-section h2 img {
	margin-right: 20px;
	vertical-align: middle;
}
.quiz-section h2 span {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 1;
    color: #071836;
    padding-right: 60px;
}
.lesson_wraper_left .quiz-section h2 span {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 1;
    color: #071836;
    width: 100%;
    max-width: 92px;
    padding-right: 15px;
}
/* =========== card =========== */
.dashbaord-card {
	padding: 20px 20px 20px 27px;
}
.page-container .MuiAlert-standardError {
	margin-bottom: 20px;
}
/* =========== loader =========== */
.loader {
	position: fixed;
	top: 0;
	z-index: 999;
	background: #454545 94;
	height: 100%;
	width: 100%;
	left: 0;
	text-align: center;
	margin: auto;
	display: flex;
	justify-content: center;
	align-items: center;
}
.loader span {
	color: #d6c38f;
}
/* =========== course =========== */
body .modal label {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 21px;
	color: #071836;
}
body .modal select, body .modal .MuiTextarea-root, body .modal .MuiSelect-select, body .modal input {
	padding: 10px 10px;
	background-color: #fff;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	color: #071836;
	border: 2px solid #e1e2e5;
	border-radius: 5px;
}
body .modal .joy-focused .MuiTextarea-textarea, body .modal .Joy-focused:before, body .modal .MuiTextarea-root.Joy-focused:before, body .modal .Mui-focused .MuiOutlinedInput-notchedOutline {
	border-color: #071836;
	box-shadow: inset 0 0 0 var(--Textarea-focusedThickness) #071836;
}
body .course-section-wrap .course-btn {
	background: transparent;
	padding: 0;
	color: #071836;
	position: relative;
	right: 0;
	padding: 0;
}
body .course-btn .tooltip {
	background-color: #faf6ec;
	border-radius: 5px;
	right: 0;
	position: absolute;
	visibility: hidden;
	padding: 5px 8px;
	min-width: 160px;
	font-weight: 500;
	border: 0.5px solid #a99764;
	top: -25px;
}
body .page-container .add_quiz_btn{
	margin-left: 10px;
}
body .course-btn .tooltip.tooltip_section {
	min-width: 235px;
}
body .course-btn:hover .tooltip {
	transition: all 0.9s;
	visibility: visible;
}
.content-modal .add-cont-btn-wrap .delete-section {
	position: absolute;
	top: auto;
	padding: 0;
	right: 288px;
	width: 22px;
	padding-top: 8px;
}
/* =========== course view =========== */
.course-info h2 {
	margin: 0px 5px 0px 0px;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-size: 30px;
	line-height: 36px;
	color: #071836;
}
.course-info .MuiSwitch-action .MuiSwitch-input{
	right: 0;
}
.course-info p {
	margin: 5px 5px 5px 0px;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 30px;
	color: #071836;
}
.course-info .instructorName p {
	margin: 0px 0px 0px 10px;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 17px;
	color: #071836;
}
.course-section-wrap{
position: relative;
}
.lock_icon_visible{
	position: absolute;
	right: 10px;
	top: 17px;
}
/* =========== Course Add Content popup =========== */
body .contents-popup {
	display: flex;
	justify-content: space-between;
	padding: 40px 30px 65px 30px;
	align-items: center;
	max-height: 130px;
}
body .contents-popup .course-btn {
	margin-right: 35px;
	margin-top: 45px;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	color: #071836;
	height: 60px;
	background-repeat: no-repeat;
	background-position: top;
	padding-top: 25px;
}
body .contents-popup .course-btn:last-child {
	margin-right: 0;
}
body .contents-popup .edit-section {
	background-image: url("../images/section.png");
}
body .contents-popup .add-assignment {
	background-image: url("../images/add-reffile.png");
}
body .contents-popup .add-reffile {
	background-image: url("../images/assignment.png");
}
body .contents-popup .add-quiz {
	background-image: url("../images/quiz.png");
}
body .contents-popup .add-summary {
	background-image: url("../images/reading.png");
}
body .contents-popup .add-video {
	background-image: url("../images/video.png");
}
body .browse input {
	position: relative;
	border-style: dashed;
}
body .browse input::file-selector-button {
	color: #fff;
	position: absolute;
	right: 0;
	padding: 10px;
	background-color: #071836;
	border-radius: 5px;
	top: 4px;
	line-height: 13px;
	border: none;
}
body .content-single-popup {
	padding: 30px 30px;
	border-radius: 5px;
}
body .MuiPopover-paper {
	box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.12);
	border-radius: 10px;
}
.content-modal .add-cont-btn-wrap {
	display: flex;
	align-items: center;
	position: relative;
}
.content-modal .add-cont-btn-wrap .edit-section {
	position: absolute;
	top: auto;
	padding: 0;
	right: 260px;
	width: 22px;
	padding-top: 9px;
}
.single-lesson.locked, .locked.course-section {
	pointer-events: none;
	opacity: 0.5;
}
// .single-lesson .Last_completed_Active h2 span{
// 	color: #a89663;
// }
.single-lesson {
	border-bottom: 1px solid #edeced;
}
.single-lesson .quiz-section h2 {
	padding: 10px 0;
}
.single-lesson .quiz-section h2 span {
	font-size: 14px;
}
/* =========== terms & condition css =========== */
.register-wrap .terms-condition {
	display: flex;
	min-width: 286px;
	align-items: center;
}
.register-wrap .terms-condition a {
	text-align: left;
}
.register-wrap .terms-condition span {
	color: #071836;
	padding: 0;
	margin-right: 10px;
}
.register-wrap .terms-condition span:hover {
	background-color: transparent;
}
.register-wrap .terms-condition path {
	color: #d6c38f;
}
.register-wrap .register-footer a {
	font-size: 14px;
	line-height: 21px;
}
/* =========== Add Student css =========== */
.content-title {
	margin-top: 0;
	font-family: "Poppins";
	font-style: normal;
	margin-bottom: 30px;
	font-weight: 700;
	font-size: 25px;
	line-height: 32px;
	color: #071836;
}
.page-container .upload-image.circle label img{
	width: 100%;
}
.page-container .upload-image.circle label{
	width: 100%;
    padding: 0;
}
.page-container .upload-image.circle {
	margin-right: 30px;
	border-radius: 50%;
	height: 210px;
	border-width: 1px;
	position: relative;
	overflow: hidden;
	max-width: 210px;
    width: 100%;
    flex-basis: 100%;
}


.login-info {
	border: 1px solid #e2e2e2;
	border-radius: 5px;
	padding: 35px 40px;
}
.login-info h2 {
	margin-top: 0;
}
.inner-container form .select-country {
	padding: 0;
}
.inner-container form .select-country .MuiInputBase-root {
	padding: 0px;
	border: 1px solid #e1e2e5;
	border-radius: 5px;
}
.inner-container form .select-country .MuiInputBase-root input {
	padding: 10px;
	padding-right: 0;
}
/* =========== parent login css =========== */
body .otp-txt {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	color: #9a909c;
}
body .otp-txt:hover {
	background-color: transparent;
}
/* =========== transition css =========== */
.transition-screen-enter {
	opacity: 0;
	transform: translateX(100px);
}
.transition-screen-enter-active {
	opacity: 1;
	transform: translateX(0);
	transition: opacity 1s, transform 1s;
}
.transition-screen-exit {
	opacity: 1;
	transform: translateX(0);
	transition: opacity 1s, transform 1s;
}
.transition-screen-exit-active {
	opacity: 0;
	transform: translateX(100px);
	transition: opacity 1s, transform 1s;
}
.dashboard-main {
	position: relative;
	min-height: 100vh;
}
/* =========== standardError css =========== */
body .MuiAlert-standardError {
	background-color: transparent;
	color: #d74343;
	padding: 10px 0px 0px 0px;
	background-color: transparent;
	color: #d74343;
	padding: 10px 0px 0px 0px;
}
.seach_hide .hide_search_dashboard {
	display: none;
}
.cartIconHeader .cartIconHeaderContainer {
	position: relative;
	display: flex;
}
.cartIconHeader .cartIconHeaderContainer .cartIconHeaderConter {
	position: absolute;
	width: 20px;
	height: 20px;
	background: #071836;
	border-radius: 50%;
	color: #fff;
	font-size: 13px;
	text-align: center;
	top: -12px;
	right: -12px;
}
.quizPanelTabel .page-container {
	padding: 0px;
	margin-top: 40px;
}
.page-container.quizeTableMainInner .rdt_TableCell {
	justify-content: center;
}
.page-container.quizeTableMainInner .rdt_TableCell:nth-child(1), .page-container.quizeTableMainInner .rdt_TableCell:nth-child(2), .page-container.quizeTableMainInner .rdt_TableCell:nth-child(3), .page-container.quizeTableMainInner .rdt_TableCell:nth-child(4) {
	justify-content: flex-start;
}
.page-container.quizeTableMainInner .rdt_TableCol {
	justify-content: center;
}
.page-container.quizeTableMainInner .rdt_TableCol:nth-child(1), .page-container.quizeTableMainInner .rdt_TableCol:nth-child(2), .page-container.quizeTableMainInner .rdt_TableCol:nth-child(3), .page-container.quizeTableMainInner .rdt_TableCol:nth-child(4) {
	justify-content: flex-start;
}
// .setting-tabs-container .page-container {
// 	padding: 0px;
// }
.course_catalog_parent .course-content h2 {
	pointer-events: none !important;
}
.feedback_wrap .page-container {
	padding: 0;
	margin-bottom: 0;
}
.feedback_wrap .page-container .bueHSC {
	margin: 0 10px;
}
.feedback_wrap .page-container .rdt_TableBody .rdt_TableCell div {
	padding: 10px 0;
	font-family: "Poppins";
	font-weight: 400;
	font-size: 11px;
	line-height: 1;
	color: #071836;
	font-weight: 700;
}

.student-detail-view [role=tabpanel] {
    background: #fff;
}
.student_report_pdf canvas,
.student-detail-view canvas,
.admin_dash_board canvas{
    box-shadow: none;
	margin-bottom: 0;
}
.student-detail-view .page-container {
    padding: 15px 15px;
}
.css-lvinth {
    max-height: 92vh;
    overflow-y: auto;
    overflow-x: hidden;
	scrollbar-width: auto;
}

.css-lvinth::-webkit-scrollbar {
	width: 10px;
}

.css-lvinth::-webkit-scrollbar-track {
	background: #eee;
}

.css-lvinth::-webkit-scrollbar-thumb {
	background: #9e9e9e;
}


// yasir abbas css responsive
// .identity_picture{display: flex;}
// .identity_picture label img {
//     width: 100%;
//     max-width: 19px;
//     position: absolute;
//     left: 15px;
//     top: 6px;
// }

// .identity_picture label {
//     padding-left: 44px;
//     display: inline-block !important;
// }
.student_detail_email {
	word-break: break-word;
}
.fullwith{width: 100%;}
.search_filter_yi .forum_searchBy__O0sIG input{min-width: inherit;}
// .forums_search  .btn-wrapper button{margin-left: 10px;}
// span.required {
//     color: red;
// 	position: absolute;
//     top: -5px;
//     right: -9px;
// }
.inner-container form label{
	position: relative;
    display: inline;
}
body .inner-container form .identity_picture label{
    display: flex;
}
body .inner-container form .Passport_Copy label{
    display: flex;
    font-size: 13px;
    text-align: center;
    line-height: 1.1;
}
body .inner-container form .Passport_Copy label img{
margin-bottom: 5px;
}
.course_catalog_parent .course-content h2 p {
    line-height: 23px;
}
blockquote {
    border-left: 5px solid #ccc;
    font-style: italic;
    margin-left: 0;
    margin-right: 0;
    overflow: hidden;
    padding-left: 1.5em;
    padding-right: 1.5em;
}
.identity_picture.home_admin label{
	padding-left: 0px;
	display: flex !important;
}
.identity_picture.home_admin label img {
    position: static;
    max-width: 100%;
    width: auto;
}
body textarea{font-family: "Poppins" !important;}


.edit_subject.page-container .upload-image.circle label img {
     width: auto
}
.edit_subject.page-container .upload-image.circle{
    border-radius: 5px;
    border: 3px dashed rgba(7, 24, 54, 0.10);
    opacity: 0.92;
    background: rgba(222, 208, 167, 0.14);
    max-width: 250px;
}


.subject_detail_page .single-lesson.locked, 
.subject_detail_page .locked.course-section{
	opacity: 1;
	pointer-events: unset;
}

.subject_detail_page .course-section {
    background-color: rgba(78, 78, 78, 0.08);
    border-radius: 5px;
}

.login-wrap .login-form input,
.login-wrap .login-form label .MuiFormControlLabel-label{
	font-family: "Poppins";
}

body .assignment_editor_wraper{

    width: 100%;
    max-width: 90%;
    border: 2px solid #000;
  
}


body .ActivityTab_wrap .rdt_TableBody .rdt_TableCell div{
padding: 5px 0;
}
body .ActivityTab_wrap .rdt_TableBody .rdt_TableCell .css_typo {
    font-size: 13px;
    position: relative;
    left: 1.5px;
}
body .ActivityTab_wrap .rdt_TableHeadRow [data-column-id="3"]{
justify-content: flex-end;
}
body .ActivityTab_wrap .rdt_TableBody [data-column-id="3"]{
justify-content: flex-end;
}

.student_subject_lessons .lesson_wraper .lesson_wraper_left .page-container .course-content .quiz-section .single-lesson,
.student_subject_lessons .lesson_wraper .lesson_wraper_left .page-container .course-content .quiz-section ,
.student_subject_lessons .lesson_wraper .lesson_wraper_left .page-container .course-content .quiz-section .single-lesson .MuiGrid-item{
    width: 100%;
	padding: 0;
}
.student_subject_lessons .lesson_wraper .lesson_wraper_left .page-container .course-content .quiz-section .single-lesson .MuiGrid-item{
    position: relative;
	// padding-right: 35px;
	padding: 0 15px;
}

.student_subject_lessons .lesson_wraper .lesson_wraper_left .page-container .course-content .quiz-section .single-lesson .MuiGrid-item.active_conten{
	background-color: #f8f4ea;
}
.student_subject_lessons .lesson_wraper .lesson_wraper_left .page-container .course-content .quiz-section .single-lesson .MuiGrid-item  h2{
    padding: 10px 15px;
padding-right: 30px;
}


.student_subject_lessons .lesson_wraper .lesson_wraper_left .page-container .course-content .course-section {
    background-color: rgb(78 78 78 / 8%);
    border-radius: 5px;
    margin-right: 5px;

}

.student_dash_subjects .subject__image {
height: 200px;
}

.student_subject_lessons .lesson_wraper .lesson_wraper_left .page-container .course-content .Completed{
width: 25px;
height: 25px;
position: absolute;
right: 15px;
background-image: url('../images/completed.png');
background-repeat: no-repeat;
top: 30px;
font-size: 0;

} 


.student-detail-view .page-container{
	margin-bottom: 0;
}

.signOut {
  color: #001c28;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
}
.signOut_tooltip {
  background-color: #fff;
  color: #000;
  display: inline-block;
  padding: 10px 10px;
  font-size: 15px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.signOut .userName {
  white-space: nowrap; /* Prevents text from wrapping to the next line */
  overflow: hidden; /* Hides the overflowed content */
  text-overflow: ellipsis; /* Adds the ellipsis (...) when text overflows */
  max-width: 115px;
}

.page-container.Grant_Retract_Subject .sc-dmctIk {
	overflow-x: inherit;
    overflow-y: inherit;
}
body .MuiMenu-list li{
	font-family: "Poppins";
}
.page-container .courseDataTable .rdt_TableBody .rdt_TableCell div {
    padding: 5px 0;
}

.read_more_btn {
    background-color: transparent;
    border: 0;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    color: #ae9c68;
}
.customSlider .slick-arrow{width: 50px; height: 50px; z-index: 999; background: #fff; border-radius: 50%; box-shadow: 0px 0px 11px rgba(64, 94, 146, 0.33);}
.customSlider .slick-arrow.slick-prev{    left: 0;
    bottom: -90px;
    top: inherit;
    right: 70px;
    margin: 0 auto;
}
.customSlider .slick-arrow.slick-prev:before{content: " "; background: url("../images/arrow-left.svg")no-repeat center/contain;width: 21px; height: 21px; display: inline-block;}
.customSlider .slick-arrow.slick-next{    
	left: 70px;
    bottom: -90px;
    top: inherit;
    right: 0;
    margin: 0 auto;
}
.customSlider .slick-arrow.slick-next:before{content: " "; background: url("../images/arrow-right.svg")no-repeat center/contain;width: 21px; height: 21px; display: inline-block;}

.chat-bubble {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
  }
  
  .chat-bubble.left {
	justify-content: flex-start;
  }
  
  .chat-bubble.right {
	justify-content: flex-end;
  }
  #loader{
	display: none;
  }
#pdfEditorSave{
	cursor: pointer;
}
@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
#pdfEditorSave.loading{
	pointer-events: none;
}
#loader {
	margin-left: 10px;
    position: relative;
    top: 2px;
}
#loader .cirlcle {
    border: 2px solid #f3f3f3; 
    border-top: 2px solid #06435d; 
	border-right: 2px solid #06435d;
	border-bottom: 2px solid #06435d;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    animation: rotate 2s linear infinite;
    position: relative;
    z-index: 9999;
    display: inline-block;
}


.message_registering_wrap {
	height: 89vh;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 600px;
}
.message_registering {
    background-color: #fff;
    text-align: center;
    width: 100%;
    max-width: 600px;
    border: 2px solid green;
    padding: 50px 0;
}

.General_Topics{
	border-bottom: 1px solid #273a5a30;
    padding-bottom: 15px;
    margin-bottom: 37px;

}
.seprater_table{
	border-bottom: 1px solid #273a5a30;
    padding-bottom: 15px;
    margin-bottom: 37px;

}
.edit_subject .inner-container form .upload-image label{position: static;}
.edit_subject form .upload-image input {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    opacity: 0;
	cursor: pointer;
}
.parent_student_list .btn-wrapper a{
	padding: 12px 30.5px;
}
.course-content .course-section h3 span.accordian_section {
    position: absolute;
    right: 0;
    padding-right: 11px;
}
.course-content .course-section h3 {
	cursor: pointer;
}

.CookieConsent {
    text-align: center;
    border-radius: 10px;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 10px;
    top: 0;
    right: 0;
    max-width: 500px;
    justify-content: center !important;
    align-items: center !important;
    margin: auto;
    max-height: 230px;
	background-color: #fff!important;
	color: #000!important;
}
.CookieConsent a{
	color: #000!important;
}
.CookieConsent h2{
	margin: 0;
	margin-bottom: 10px;
}

body.has-cookie-consent:before {
    content: "";
    background-color: #00000073;
    position: fixed;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    bottom: 0;
    top: 0;
    z-index: 999;
}
body.has-cookie-consent {
  overflow: hidden;
}
.textarea-custom {
	
	width: 100%;
	padding: 14px 37px;
	font-size: 16px;
	border-radius: 30px;
	border: 1px solid rgba(15, 32, 63, 0.09);
	resize: none;
	outline: none;
	box-sizing: border-box
  }
  
  /* Styling the placeholder text */
  .textarea-custom::placeholder {
	color: #8888889c; /* Change this to the color you want */
	opacity: 1; /* Ensures full opacity (some browsers apply default opacity to placeholders) */
  }
  

@media screen and (max-width: 1680px) {
	
	.personal_info_yi .css-1lzxzf5-MuiGrid-root .MuiFormControl-root{margin-left: 20px;}
	.table_refund_wapper #cell-6-30 {
		min-width: 160px;
	}
	.yi_my_filter .css-1qpzhgb-MuiGrid-root {
		max-width: 60%;
		flex-basis: 60%;
	}
	
	.yi_my_filter .css-u78q51-MuiGrid-root {
		max-width: 220px;
		flex: 100%;
	}
	
	body .yi_my_filter .css-1o7apob-MuiGrid-root {
		flex: 100%;
		max-width: 200px;
		margin-left: auto;
		margin-right: 0;
		padding-left: 0;
	}
	.settingGeneralMain .settingGeneralContainer .settingGeneralForm .settingGeneralFormFieldBox:first-child .settingGeneralFormFieldInput {width: calc(60% - 160px);}
	.search_filter_yi .btn-wrapper a{padding: 12px 22px;}
	.search_filter_yi .btn-wrapper .forum_Post_a_thread__sebCj{min-width: 145px;}
	body .yi_four_boxes button {font-size: 10px;}
	body .course-info .btn-wrapper a{width: 150px;padding: 12px 30px;}
	.page-container .upload-image.circle {height: 190px;max-width: 190px;width: 100%;flex-basis: 100%;}
	.page-container .upload-image.profile label{font-size: 15px;}
	.page-container .upload-image.circle img{object-fit: contain;}
	.page-container .browse .btn {
		padding: 12px 39px;
	}
	
}

@media screen and (max-width: 1550px) {
	body .main-content{padding: 70px 40px;}

}
@media screen and (max-width: 1500px) {
	.user_student_listing_table .yi_my_filter.MuiGrid-container {
		justify-content: space-between;
	}
	.user_student_listing_table .yi_my_filter .MuiGrid-grid-xs-9 {
		max-width: 40%;
	}
}
@media screen and (max-width: 1400px) {
	body .assignment_editor_wraper{
		top: 60%;
	
	}
}
@media screen and (max-width: 1280px) {
	.user_student_listing_table .yi_my_filter.MuiGrid-container form{
		padding-bottom: 0;
	}
	.user_student_listing_table .yi_my_filter.MuiGrid-container form .MuiFormControl-root{
		width: 60%;
	}
	
}
@media screen and (max-width: 1199px) {
	
	.user_student_listing_table .yi_my_filter.MuiGrid-container form .MuiFormControl-root{
		width: 100%;
	}
	.user_student_listing_table .yi_my_filter.MuiGrid-container form {
		margin-bottom: 15px;
	}
	.user_student_listing_table .page-container  {
		margin-top: 15px;
	}
	
}




@media screen and (max-width: 1450px) {
	body .course-content .course-section h3 .sec_title {
        max-width: 100px;
        padding-right: 15px;
    }
	.lesson_wraper .page-container {
		padding: 25px 20px;
	}
	.course-content .course-section h3 {
		padding-right: 35px;
		font-size: 16px;
	}
	.page-container .browse .btn {
		padding: 12px 19px;
		width: 100%;
		max-width: 100%;
	}
	.personal_info_yi .css-1rktu89-MuiGrid-root .css-1osj8n2-MuiGrid-root,
	body .personal_info_yi .css-1rktu89-MuiGrid-root .MuiFormControl-root,
	body .yi_info_profile_div .MuiFormControl-root{margin-left: 20px;}
	body .Mui-error {
		margin-left: 0;
	}
	body .yi_my_filter .css-u78q51-MuiGrid-root {
		max-width: 157px;
		flex: 100%;
	}
	.css-1h5me87-MuiGrid-root{max-width: 30%;flex-basis: 30%;}
	.dashboard_viewTransactionsContainer__M6CbP h1 + button a {
		padding: 0 !important;
	}
	.innerContainer .transationsFieldContainer{width: calc(100% - 75px);}
	body .main-content{padding: 70px 40px;}
	body .main-content.dashboard_main_close{margin-left: -232px;}

	.personal_info_yi .css-1qpzhgb-MuiGrid-root {max-width: 60%;flex-basis: 60%;}
	.personal_info_yi .css-1rktu89-MuiGrid-root {max-width: 60%;}
	.personal_info_yi .css-1rktu89-MuiGrid-root .css-1osj8n2-MuiGrid-root {flex-basis: 70%;max-width: 70%;}
	.personal_info_yi .css-1qpzhgb-MuiGrid-root .css-1osj8n2-MuiGrid-root {flex-basis: 70%;max-width: 70%;}
	body .yi_four_boxes .css-1equabv-MuiGrid-root {max-width: 33.33%;flex-basis: 33.33%;}
	.search_filter_yi .forum_select_filter__PUzMz {margin-right: 10px;}
	.search_filter_yi .btn-wrapper a{margin-right: 0;}
	body .dashboard-footer{padding: 0 30px;}
	body .transations_innerContainer__UYZXc .transations_transationsFieldContainer__l7TQG .transations_transationsFieldDateFrom__QeVlS label,
	body .transations_innerContainer__UYZXc .transations_transationsFieldContainer__l7TQG .transations_transationsFieldDateTo__NtCmO label{top: -5px;}
	body .transations_innerContainer__UYZXc .transations_transationsFieldContainer__l7TQG .transations_transationsFieldDateFrom__QeVlS input,
	body .transations_innerContainer__UYZXc .transations_transationsFieldContainer__l7TQG .transations_transationsFieldDateTo__NtCmO input{padding: 11px;}
	body .transations_innerContainer__UYZXc .transations_transationsFieldContainer__l7TQG .transations_transationsFieldEmail__fZDq6 input,
	body .transations_innerContainer__UYZXc .transations_transationsFieldContainer__l7TQG .transations_transationsFieldName__svhe1 input{padding: 10px;}
	body .transations_innerContainer__UYZXc .transations_transationsBtn__yKGNH button {padding: 10px 18px;line-height: normal;}
	.sidebar-menu .menu-wrap{padding-left: 26px;}
	body .main-header{width: calc(100% - 268px);margin-left: 268px;}
	body .dashboard_main_close .main-header{width:100%;margin-left: 0;}
	body .side-bar,
	body .side-bar .MuiDrawer-paper{width: 260px;}
	.dashboard_chart_select_wrap__D-XVj {display: flex;	margin-left: 12px;padding-top: 20px;}
	.dashboard-main .dashboard_viewTransactionsContainer__M6CbP h1, 
	body .dashboard-main .dashboard_chart_title__x3zpf {font-size: 22px;line-height: 38px;padding-top: 22px;}
	body .dashbaord-card{height: 100%;align-items: center;}
	body .btn-wrapper a{padding: 12px 30px;margin-right: 20px;}
	body h1.main-heading{font-size: 33px;line-height: 46px;width: 100%;max-width: 361px;}
	body .hide_search_dashboard h1.main-heading{margin-left: auto;max-width: 880px;margin-right: auto;}
	// body .main-content{width: 100%;max-width: 100%;margin: auto;}
	body .yi_info_profile_div{flex-wrap: nowrap;}
	// body .css-s7s3c1-MuiGrid-root {max-width: 35%;flex-basis: 35%;}
	body .student-detail-view .css-5faa5k-MuiGrid-root {
		padding-left: 25px;
	}

	.css-5faa5k-MuiGrid-root {
		padding-left: 20px;
	}
	
	.parent_student_list .btn-wrapper a {
		margin-right: 0;
	}
}

@media screen and (max-width: 1373px) {
	.edit_subject.page-container .upload-image.circle {
		max-width: 168px;
	}
}



@media screen and (max-width: 1280px) {
	.personal_info_yi .css-1lzxzf5-MuiGrid-root .MuiFormControl-root,
	.personal_info_yi .css-1rktu89-MuiGrid-root .css-1osj8n2-MuiGrid-root,
	 body .personal_info_yi .css-1rktu89-MuiGrid-root .MuiFormControl-root, 
	body .yi_info_profile_div .MuiFormControl-root{margin-left: 0px;}
	.yi_four_boxes .css-9fc0yf-MuiGrid-root {justify-content: center;text-align: center;}
	.yi_four_boxes .css-1gejpqg-MuiGrid-root {text-align: center;margin-top: 10px;}
	.course-info .css-121w2j5-MuiGrid-root {
		overflow: hidden;
		display: flex;
		align-items: center;
		max-width: 100px;
		flex: 100%;
		height: 100px;
		padding: 0;
		margin-right: 21px;
	}
	
	.course-info .css-121w2j5-MuiGrid-root img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	body .page-container.edit_subject .btn{margin-left: 0;}
	body .yi_my_filter .css-1qpzhgb-MuiGrid-root {
		max-width: 480px;
		flex-basis: 100%;
	}
	.edit_subject .css-1qpzhgb-MuiGrid-root{order: 2;}
	.page-container .upload-image.circle{order: 1;margin-bottom: 20px;}
	body .main-content .admin_dash_board .MuiGrid-root {
		margin-left: 0;
		width: 100%;
	}
	body .yi_info_profile_div{flex-wrap: wrap;}
	.yi_info_profile_div .css-1osj8n2-MuiGrid-root {max-width: 100%;flex-basis: 100%;padding-top: 10px;}
	// body .css-1osj8n2-MuiGrid-root,
	// body .css-1rktu89-MuiGrid-root {max-width: 100%;flex-basis: 100%;padding-top: 10px;}
	body .yi_info_profile_div .upload-image.circle {order: 1;margin-bottom: 26px;}
	body .yi_info_profile_div .css-1qpzhgb-MuiGrid-root {order: 2;max-width: 100%;flex: 100%;}
	body .css-1lhr264-MuiGrid-root>.MuiGrid-item{width: 100%;}
	body .css-1lhr264-MuiGrid-root {margin: 0;width: 100%;}
	body .search_filter_yi .css-k3f0g4-MuiGrid-root {max-width: 50%;flex-basis: 50%;}
	body .courese_single_col_wapper_yi {max-width: 800px;flex-basis: 100%;margin-bottom: 30px;}
	.personal_info_yi .css-1rktu89-MuiGrid-root,
	.personal_info_yi .css-1qpzhgb-MuiGrid-root{max-width: 64%;flex-basis: 64%;}
	body .css-5faa5k-MuiGrid-root,
	// body .css-gj1fbr-MuiGrid-root,
	.personal_info_yi .css-1o7apob-MuiGrid-root {max-width: 100%;flex-basis: 100%;}
	.css-uh2u7x-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root,
	.css-1u3bzj6-MuiFormControl-root-MuiTextField-root,
	.css-1u3bzj6-MuiFormControl-root-MuiTextField-root{width: 100%;}
	// body .css-u78q51-MuiGrid-root,
	.edit_subject .css-1qpzhgb-MuiGrid-root,
	body .css-1iwf9qe-MuiGrid-root,
	body .css-1equabv-MuiGrid-root,
	.personal_info_yi .css-1xwb152-MuiGrid-root {margin: 0;max-width: 100%;flex-basis: 100%;padding-top: 10px;padding-bottom: 10px;padding-left: 0;}
	.personal_info_yi .css-18ddebd-MuiGrid-root {margin: 0;max-width: 100%;flex-basis: 100%;padding-top: 10px;padding-bottom: 10px;padding-right: 0;}
	.personal_info_yi .css-1lzxzf5-MuiGrid-root {max-width: 100%;flex-basis: 100%;padding-left: 0;padding: 0;}
	.personal_info_yi .css-1rktu89-MuiGrid-root .css-1osj8n2-MuiGrid-root,
	.personal_info_yi .css-1qpzhgb-MuiGrid-root .css-1osj8n2-MuiGrid-root {flex-basis: 100%;max-width: 100%;padding-top: 10px;}
	body .personal_info_yi .upload-image label img{width: auto;height: auto;}
	.page-container .rdt_TableBody div .rdt_TableCell {width: 180px;line-height: 1.2;}
	.page-container .rdt_TableHead .rdt_TableHeadRow div {width: 150px;}
	.course-info .MuiGrid-root.css-1qt5cl2-MuiGrid-root {max-width: 66%;}
	body .main-content{padding: 75px 25px;}
	.dashboard-main .col_2_yi{flex-basis: 100%;max-width: 100%;}
	.dashbaord-card h3{font-size: 26px;}
	// body .upload-image label img{width: 100%;height: 100%;}
	.student-detail-view_yi .css-ez1m8-MuiGrid-root {max-width: 100%;flex-basis: inherit;}
	.student-detail-view_yi .css-49qqw7-MuiGrid-root {max-width: 100%;flex-basis: inherit;}
	.student-detail-view_yi .css-decnt1-MuiGrid-root {max-width: 100%;flex-basis: 100%;margin: 0;}
	.student-detail-view_yi .css-1e830u-MuiGrid-root {max-width: 100%;flex-basis: inherit;}
	.forums_search > .btn-wrapper.MuiBox-root a {    margin-right: 0;padding: 10px 14px;font-size: 13px;height: 40px;position: relative;top: 3px;}
	body .css-1pysjk2-MuiGrid-root,
	body .css-153dbfs-MuiGrid-root,
	body .css-1xwb152-MuiGrid-root,
	body .css-18ddebd-MuiGrid-root,
	body .css-1lzxzf5-MuiGrid-root,
	body .personal_info_yi .css-9jlj8x-MuiGrid-root,
	body .css-kp78ug-MuiGrid-root,
	body .css-1h5me87-MuiGrid-root{max-width: 100%;flex-basis: 100%;padding-top: 10px;margin: 0;padding-left: 0;padding-right: 0;}
	// body .css-mhc70k-MuiGrid-root{margin-left: 0;width: 100%;padding: 0 20px;}
	body .css-mhc70k-MuiGrid-root{margin-left: 0;width: 100%;}
	.course-content {padding: 0 10px;}
	body .main-content.css-cdyy6c {margin-left: -265px;}
	body .manage-discount_paymentMain__RvwCA .css-mhc70k-MuiGrid-root {padding: 0;}
	body .manage-discount_paymentMain__RvwCA .css-mhc70k-MuiGrid-root .css-1idn90j-MuiGrid-root {padding-left: 0;}
	body .manage-discount_paymentMain__RvwCA .css-mhc70k-MuiGrid-root .css-1osj8n2-MuiGrid-root {padding-left: 0;}
	body .manage-discount_paymentMain__RvwCA .css-mhc70k-MuiGrid-root .css-gj1fbr-MuiGrid-root {padding-left: 0;}
	body .manage-discount_paymentMain__RvwCA .css-mhc70k-MuiGrid-root .css-1o7apob-MuiGrid-root {padding-left: 0;}
	body .manage-discount_paymentMain__RvwCA .manage-discount_payment_Type__LVoHc .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{width: 100%;}

.student_subject_lessons .lesson_wraper .lesson_wraper_left .page-container{
		margin-bottom: 0;
		padding: 15px 20px;
	}
.student_subject_lessons .lesson_wraper .lesson_wraper_left .page-container .course-section{
		padding: 0;
	}
.student_subject_lessons .lesson_wraper .lesson_wraper_left .page-container .course-content{
		padding: 0;
	}
.student_subject_lessons .lesson_wraper .lesson_wraper_left .page-container .course-content .quiz-section h2 span{
	padding-right: 20px;
}
.student_subject_lessons .lesson_wraper .lesson_wraper_left .page-container .course-content .quiz-section 
.quiz-section h2 img {
    margin-right: 15px;
}
body .side-bar,
	body .side-bar .MuiDrawer-paper{width: 205px;}
	body .main-header {
        width: calc(100% - 205px);
        margin-left: 205px;
    }
	body .main-content.css-cdyy6c {
        margin-left: -205px;
    }
	.sidebar-menu .menu-wrap {
        padding-left: 5px;
    }
	.sidebar-menu .menu-wrap {
		margin-top: 27px;
	}
}



@media screen and (max-width: 1150px) {
	.loginUser .cart_show a {
		position: absolute;
		right: 0px;
		top: 11px;
	}
	body .yi_my_filter .css-1qpzhgb-MuiGrid-root {
		max-width: 350px;
		flex-basis: 100%;
	}
	body .yi_my_filter .css-u78q51-MuiGrid-root {
		max-width: 127px;
		flex: 100%;
	}
	body .yi_my_filter .css-1o7apob-MuiGrid-root {
		flex: 100%;
		max-width: 170px;
		margin-left: AUTO;
		margin-right: 0;
		padding-left: 0;
	}
	body .yi_my_filter .btn{padding: 11px 15px;}
    body .assignmentOrText{margin: 0px 20px 0px 20px}
	.forums_search .forum_searchBy__O0sIG input{min-width: 211px;}
	body .tab button{font-size: 14px;padding: 15px 34px;}
	body .course-content .course-section h3,
	body .quiz-section h2 span{font-size: 15px;}
	body .quiz-section h2 img{width: 20px;margin-right: 10px;}
	.personal_info_yi .css-153dbfs-MuiGrid-root,
	.personal_info_yi .css-9jlj8x-MuiGrid-root,
	.personal_info_yi .css-1rktu89-MuiGrid-root,
	.personal_info_yi .css-1qpzhgb-MuiGrid-root {padding: 0;max-width: 100%;flex-basis: 100%;}
	body .css-1on7o35-MuiGrid-root,
	body .css-s7s3c1-MuiGrid-root,
	body .css-s7s3c1-MuiGrid-root {max-width: 100%;flex-basis: inherit;}
	.teachers_add_yi .css-1osj8n2-MuiGrid-root {max-width: 100%;flex-basis: inherit;padding-top: 10px;}
	body .css-9drkqd-MuiGrid-root,
	body .css-1lj5egr-MuiGrid-root,
	.teachers_add_yi .css-1rktu89-MuiGrid-root {max-width: 100%;flex-basis: 100%;}
	.teachers_add_yi .css-18ddebd-MuiGrid-root {max-width: 100%;flex-basis: 100%;padding-top: 7px;margin: 0;padding-right: 0;}
	.teachers_add_yi .css-1lzxzf5-MuiGrid-root {max-width: 100%;flex-basis: 100%;padding: 0;padding-top: 10px;}
	.teachers_add_yi .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-2.css-1xwb152-MuiGrid-root {padding-left: 0;max-width: 100%;padding-top: 10px;}
	.teachers_add_yi .css-9jlj8x-MuiGrid-root {max-width: 100%;flex-basis: 100%;padding: 0;}	
	.teachers_add_yi .css-153dbfs-MuiGrid-root {max-width: 100%;flex-basis: 100%;padding: 0;}
	.teachers_add_yi .css-1qpzhgb-MuiGrid-root {max-width: 100%;flex-basis: 100%;}
	body .yi_four_boxes .css-1equabv-MuiGrid-root {max-width: 50%;flex-basis: 50%;}
	.search_filter_yi .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {width: 100%;}
	.search_filter_yi .css-wb57ya-MuiFormControl-root-MuiTextField-root {width: 100%;}
	.search_filter_yi .css-k3f0g4-MuiGrid-root {max-width: 100%;flex-basis: inherit;}
	body .search_filter_yi .css-k3f0g4-MuiGrid-root {max-width: 100%;flex-basis: 100%;}
	.search_filter_yi .forum_select_filter__PUzMz{width: 100%;}
	.search_filter_yi .forum_select_filter__PUzMz .MuiFormControl-root {width: 100%;}
	.search_filter_yi .css-1osj8n2-MuiGrid-root {max-width: 100%;flex-basis: 100%;padding-bottom: 24px;}
	.add_forum_yi .forum_searchBy__O0sIG input {min-width: 201px;}
	.add_forum_yi .css-17yjn0j-MuiGrid-root .MuiGrid-root {max-width: 100%;flex-basis: inherit;padding-top: 12px;}
	.add_forum_yi .css-17yjn0j-MuiGrid-root {margin: 0;}
	.add_forum_yi .css-16y7sth-MuiGrid-root .MuiGrid-root {max-width: 100%;flex-basis: inherit;padding-top: 6px;}
	.add_forum_yi .css-16y7sth-MuiGrid-root {margin: 0;}
	.add_forum_yi .css-17yjn0j-MuiGrid-root .MuiGrid-root {max-width: 100%;flex-basis: inherit;padding-top: 12px;}
	.add_forum_yi .css-17yjn0j-MuiGrid-root {margin: 0;}
	.add_forum_yi .css-16y7sth-MuiGrid-root .MuiGrid-root {max-width: 100%;flex-basis: inherit;padding-top: 6px;}
	.add_forum_yi .css-16y7sth-MuiGrid-root {margin: 0;}
	.forums_search .btn-wrapper.MuiBox-root {display: flex;}
	.forums_search > .btn-wrapper.MuiBox-root a {    margin-right: 0;padding: 9px 13px;font-size: 12px;height: 40px;position: relative;top: 3px;}
	body .forum_searchBy__O0sIG .btn{padding: 10px 23px;}
	.course-info .MuiGrid-root.css-1qt5cl2-MuiGrid-root {max-width: 66%;}
	.edit_subject .css-17yjn0j-MuiGrid-root {flex-wrap: wrap;margin: 0;}	
	.edit_subject .css-17yjn0j-MuiGrid-root .MuiGrid-root {margin: 0;max-width: 100%;flex-basis: inherit;padding-top: 7px;}
	.edit_subject > .inner-container {padding: 0;}
	body .dashbaord-card {padding: 25px 10px 25px 25px;}
	body .footer_footer__h3z0R .footer_footer_links__875rE ul li span {margin: 0px 25px;}
	.dashboard_viewTransactionsContainer__M6CbP > div:nth-child(2){margin-right: 33px;}
	.sc-hLBbgP.sc-eDvSVe.sc-jSUZER.eVPPvF.dAJBHh.DlhbO.rdt_TableCell,
	.sc-hLBbgP.sc-eDvSVe.sc-ipEyDJ.glyQNg.dAJBHh.dlSNBX.rdt_TableCol,
	.sc-hLBbgP.sc-eDvSVe.sc-jSUZER.eVPPvF.bQdYbR.DlhbO.rdt_TableCell,
	.sc-hLBbgP.sc-eDvSVe.sc-ipEyDJ.glyQNg.bQdYbR.dlSNBX.rdt_TableCol {min-width: 180px;}
	.transations_innerContainer__UYZXc .transations_transationsBtn__yKGNH:last-child {margin-left: 8px;}
	body .btn{padding: 5px 15px;}
	.dashboard-main .col_6_yi_main > .MuiGrid-root{max-width: 50%;flex-basis: 50%;}
	.loginUser .MuiGrid-root{width: 100%;margin: auto;}
	.loginUser .MuiAvatar-root{margin: auto;}
	.loginUser .userName{padding-top: 6px;}
	.loginUser {justify-content: right;display: flex;}
	.loginUser .MuiGrid-container {justify-content: center;}
	body .top_header{padding: 13px 20px 13px 57px;}
	body .togglebutton{width: 34px;height: 34px;top: 43px;}
	.top_header_inner a{font-size: 17px;}
	.sidebar-menu .menu-wrap > li a,
	.sidebar-menu .menu-wrap li.has-child span a{padding: 15px 0px 15px 55px;}
	.sidebar-menu .menu-wrap li a{font-size: 14px;}
	.sidebar-menu .menu-wrap > li,
	.sidebar-menu .menu-wrap > li .users-icon{background-position: 18px center;}

}

.faq-select {
    font-size: 14px;
    padding: 11px 15px;
    font-family: "Poppins";
    width: 100%;
    border: 2px solid #e0e3e7;
    border-radius: 5px;
}

@media screen and (max-width: 980px) {
	.MuiDrawer-root {
		position: absolute;
   }
	body .main-content {
		margin: 0px;
		padding: 90px 30px;
   }
   body .main-content.dashboard_main_close {margin-left: 0;}
}
@media screen and (max-width: 899px) {
	.login-wrap {
		padding: 40px 35px !important;
	}
}
@media screen and (max-width: 650px) {
	.register-wrap .terms-condition {
		flex-wrap: wrap;
	}
	.register-wrap .terms-condition a {
		width: calc(100% - 35px);
	}
	.register-wrap .forget-link {
		width: 100%;
	}
}
