 /* ============= footer css ============= */

 $offWhite: #fffefd;
 $black: #071836;


  
  .footer {
	padding-bottom: 12px;
	text-align: center;
	.footer_text {
		p {
			font-family: "Poppins";
			text-align: center;
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 29px;
			
		}
	}
	.footer_links {
		ul {
			li {
				display: inline;
				list-style-type: none;
				span {
					margin: 0px 35px ;
				}
				a {
					font-family: "Poppins";
					text-decoration: none;
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 29px;
					text-align: center;
				}
			}
		}
	}
}

.dashboard-footer {
	position: absolute;
	width: 1280px;
	left: 0;
	bottom: 0;
	right: 0;
	margin: auto;
	.footer {
		display: flex;
		justify-content: space-between;
		p {
			// color: $black;
		}
		ul {
			li {
				a {
					// color: $black;
				}
			}
		}
	}
}